<template>
  <div>
    <!-----------------------------------
      Start Header Component
      ------------------------------------>
    <Header></Header>
    <!-----------------------------------
      End Header Component
      ------------------------------------>
    <div class="form m-auto lg:w-5/12 lg:rounded bg-white p-8 pt-44">
      <form class="rounded bg-white">
        <div class="pb-6 space-y-3 justify-center">
          <div class="font-semibold text-4xl text-gray-900 pb-2">
            {{ $t("formT") }}
          </div>
          <div class="font-normal text-lg text-gray-500 pb-12">
            {{ $t("formP") }}
          </div>
          <div class="flex">
            <div class="mr-8 w-1/2">
              <div class="font-medium text-sm text-gray-700 mb-1.5">
                {{ $t("nom") }}
              </div>
              <input
                type="text"
                name="surname"
                placeholder="Votre nom"
                @input="removeSurname"
                v-model="surname"
                :class="[
                  'border border-gray-300 rounded-lg p-3 w-full',
                  errorSurname ? 'border-red-700' : '',
                ]"
              />
            </div>
            <div class="w-1/2">
              <div class="font-medium text-sm text-gray-700 mb-1.5">
                {{ $t("prenom") }}
              </div>
              <input
                type="text"
                name="name"
                placeholder="Votre prénom"
                @input="removeName"
                v-model="name"
                :class="[
                  'border border-gray-300 rounded-lg p-3 w-full',
                  errorName ? 'border-red-700' : '',
                ]"
              />
            </div>
          </div>
          <div class="font-medium text-sm text-gray-700">
            {{ $t("date") }}
          </div>
          <Datepicker
            :previewFormat="previewDate"
            format="MM/dd/yyyy"
            placeholder="--/--/----"
            @input="removeDate"
            v-model="date"
          />
          <div class="font-medium text-sm text-gray-700">
            {{ $t("lieu") }}
          </div>
          <input
            type="text"
            name="lieu"
            placeholder="Lieu"
            @input="removeLieu"
            v-model="lieu"
            :class="[
              'border border-gray-300 rounded-lg p-3 w-full',
              errorLieu ? 'border-red-700' : '',
            ]"
          />
          <div class="font-medium text-sm text-gray-700">
            {{ $t("nationalite") }}
          </div>
          <div>
            <selectBases
              type="text"
              name="nationalite"
              placeholder="Votre nationalité"
              @input="removeNationality"
              v-model="nationality"
              @updateCountry="updateCountry"
            ></selectBases>
          </div>
          <div class="font-medium text-sm text-gray-700">
            {{ $t("residence") }}
          </div>
          <div>
            <selectBases
              type="text"
              name="residence"
              placeholder="Votre pays de résidence"
              @input="removeResidence"
              v-model="residence"
              @updateCountry="updateCountryResidence"
            ></selectBases>
          </div>
          <!--<input
            type="text"
            name="nationalite"
            placeholder="Votre nationalité"
            @input="removeNationality"
            v-model="nationality"
            :class="[
              'border border-gray-300 rounded-lg p-3 w-full',
              errorNationality ? 'border-red-700' : '',
            ]"
          />-->
          <div class="font-medium text-sm text-gray-700">
            {{ $t("email") }}
          </div>
          <input
            type="Email"
            name="email"
            placeholder="Votre email"
            @input="removeEmail"
            v-model="email"
            :class="[
              'border rounded-lg p-3 w-full',
              errorEmail ? 'border-red-700' : '',
            ]"
          />
          <div class="font-medium text-sm text-gray-700">
            Autre adresse email <span class="text-gray-400">(optionnel)</span>
          </div>
          <input
            type="Email"
            name="email"
            placeholder="Votre email"
            v-model="email2"
            :class="[
              'border rounded-lg p-3 w-full',
              errorEmail2 ? 'border-red-700' : '',
            ]"
          />
          <div class="font-medium text-sm text-gray-700">
            Numéro de téléphone WhatsApp
            <span class="text-gray-400">(avec indicatif pays)</span>
          </div>
          <div
            :class="[
              'flex border rounded-lg w-full',
              errorTel ? 'border-red-700' : '',
            ]"
          >
            <div>
              <selectBase @updateCountryCode="updateCountryCode"></selectBase>
            </div>
            <input
              type="tel"
              name="tel"
              placeholder="67 XX XX XX"
              @input="removeTel"
              v-model="tel"
              class="pl-2"
            />
          </div>
          <div class="font-medium text-sm text-gray-00">
            {{ $t("diplome") }}
          </div>
          <div>
            <Multiselect
              v-model="diplome"
              :options="diplomeList"
              @input="removeDiplome"
              placeholder="Sélectionnez le diplôme"
              :class="[
                'multiselect border border-gray-300 rounded-lg p-3 w-full',
                errorDiplome ? 'border-red-700' : '',
              ]"
            ></Multiselect>
          </div>
          <div class="font-medium text-sm text-gray-700">
            {{ $t("intitule") }}
          </div>
          <input
            type="text"
            name="intitule"
            placeholder="Intintulé"
            @input="removeIntitule"
            v-model="intitule"
            :class="[
              'border border-gray-300 rounded-lg p-3 w-full',
              errorIntitule ? 'border-red-700' : '',
            ]"
          />
          <div class="font-medium text-sm text-gray-700">
            {{ $t("message") }}
          </div>
          <textarea
            name="message"
            cols="30"
            rows="10"
            maxlength="300"
            @input="removeMessage"
            v-model="message"
            :class="[
              'w-full h-24 border border-solid rounded-lg p-3',
              errorMessage ? 'border-red-700' : '',
            ]"
          ></textarea>
          <div class="pt-2" @click="checkForm">
            <button
              type="submit"
              class="w-full h-12 font-medium text-lg leading-5 text-white bg-greenNew1 rounded-lg cursor-pointer"
            >
              {{ $t("sendButton") }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-----------------------------------
  Start Footer Component
  ------------------------------------>
    <Footer></Footer>
    <!-----------------------------------
  End Footer Component
  ------------------------------------>
  </div>
</template>

<script>
import http from "@/plugins/https";
/*Import Components */
import Header from "../../components/header/index.vue";
import Footer from "../../components/footer/index.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import selectBase from "../../components/countrie/select-base.vue";
import Multiselect from "@vueform/multiselect";
import selectBases from "../../components/countrie/select-bases.vue";
export default {
  name: "index",
  components: {
    Header,
    Footer,
    Datepicker,
    selectBase,
    selectBases,
    Multiselect,
  },
  data() {
    return {
      AllCountry: [],
      icons: {},
      images: {},
      country: {},
      diplome: null,
      diplomeList: [
        "Baccalauréat",
        "BAC+1",
        "BAC+2",
        "Licence",
        "Maitrise",
        "Master",
        "Doctorat",
      ],
      date: null,
      errorSurname: false,
      errorName: false,
      errorDate: false,
      errorLieu: false,
      errorNationality: false,
      errorResidence: false,
      errorEmail: false,
      errorEmail2: false,
      errorTel: false,
      errorDiplome: false,
      errorIntitule: false,
      errorMessage: false,
      nationality: "Bénin",
      residence: "Bénin",
      code: "+229",
    };
  },
  methods: {
    updateCountry(value) {
      this.country = value;
      // console.log(this.country);
      this.nationality = this.country.name;
    },
    updateCountryResidence(value) {
      // this.country = value;
      // console.log(this.country);
      this.residence = value.name;
    },
    updateCountryCode(value) {
      // console.log(value);
      this.code = value.dial_code;
    },
    openSuccess() {
      this.$router.push({ path: "/success" });
    },
    async sendData(dataD) {
      await http
        .post("https://pdf.41devs.com/api/v1/kmailler/send", {
          sender: "contact@remaapp.com",
          receiver: "contact@remaapp.com",
          object: "Nouvelle Inscription GIS",
          content: dataD,
        })
        .then((response) => {
          console.log("Success!", response);
        })
        .catch((error) => {
          console.error("Error!", error.message);
        });
    },

    async sendDataSheet() {
      await http
        .post(
          "https://tools.remaapp.com/api/v1/services/sheet",
          {
            id: "15S1Ab4M3Uf4UTHshr1B5bV77IQq080koP6VSNTzSRR8",
            name: this.name,
            surname: this.surname,
            email: this.email,
            birthday: this.date,
            country: this.residence,
            whatsapp: this.code + this.tel,
            profession: this.diplome,
            intitule: this.intitule,
            description: this.message,
          },
          {
            headers: {
              "x-api-key": "a2f2db80-6d5a-4a95-adf3-6b66a778d48b",
            },
          }
        )
        .then((response) => {
          console.log("Success!", response);
        })
        .catch((error) => {
          console.error("Error!", error);
        });
    },

    async checkForm(e) {
      if (
        this.surname &&
        this.name &&
        this.date &&
        this.lieu &&
        this.nationality &&
        this.residence &&
        this.email &&
        this.tel &&
        this.diplome &&
        this.intitule &&
        this.message
      ) {
        const dataD = `<b>Nom de famille:</b> ${this.surname} <br />
      <b>Prénoms:</b> ${this.name} <br />
      <b>Date de naissance:</b> ${this.date} <br />
      <b>Lieu de naissance:</b> ${this.lieu} <br />
      <b>Nationalité:</b> ${this.nationality} <br />
      <b>Pays de résidence:</b> ${this.residence} <br />
      <b>Adresse email:</b> ${this.email} <br />
      <b>Autre adresse email (optionnel):</b> ${this.email2} <br />
      <b>Numéro WhatsApp:</b> ${this.code} ${this.tel} <br />
      <b>Diplôme obtenu le plus élevé</b> ${this.diplome} <br />
      <b>Intitulé du diplôme le plus élevé</b> ${this.intitule} <br />
      <b>Description:</b> ${this.message} <br />
      `;
        this.sendData(dataD);
        this.sendDataSheet();
        this.openSuccess();
      }
      this.errors = [];

      if (!this.surname) {
        this.errorSurname = true;
      }
      if (!this.name) {
        this.errorName = true;
      }
      if (!this.date) {
        this.errorDate = true;
      }
      if (!this.lieu) {
        this.errorLieu = true;
      }
      if (!this.nationality) {
        this.errorNationality = true;
      }
      if (!this.residence) {
        this.errorResidence = true;
      }
      if (!this.email) {
        this.errorEmail = true;
      }
      if (!this.email2) {
        this.errorEmail2 = false;
      }
      if (!this.tel) {
        this.errorTel = true;
      }
      if (!this.diplome) {
        this.errorDiplome = true;
      }
      if (!this.intitule) {
        this.errorIntitule = true;
      }
      if (!this.message) {
        this.errorMessage = true;
      }

      e.preventDefault();
    },
    removeSurname() {
      this.errorSurname = false;
    },
    removeName() {
      this.errorName = false;
    },
    removeDate() {
      this.errorDate = false;
    },
    removeLieu() {
      this.errorLieu = false;
    },
    removeNationality() {
      this.errorNationality = false;
    },
    removeResidence() {
      this.errorResidence = false;
    },
    removeEmail() {
      this.errorEmail = false;
    },
    removeEmail2() {
      this.errorEmail2 = false;
    },
    removeTel() {
      this.errorTel = false;
    },
    removeDiplome() {
      this.errorDiplome = false;
    },
    removeIntitule() {
      this.errorIntitule = false;
    },
    removeMessage() {
      this.errorMessage = false;
    },
    previewDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.multiselect {
  height: 48px !important;
  border-radius: 8px !important;
}
</style>
